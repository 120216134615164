import { EuiRadio } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { FlexGroup } from '../../_eui_components';
import { Text } from '@tecton/ComponentRedesign';

interface TectonRadioProps {
  checked?: boolean;
  label: React.ReactNode;
  id: string;
  onClick: () => void;
  description?: string;
  disabled?: boolean;
}

const StyledRadio = styled(EuiRadio)<{ checked?: boolean }>`
  .euiRadio__circle {
    background-color: ${({ theme, checked }) =>
      checked ? theme.colors.fullShade : theme.colors.emptyShade} !important;
    border-color: unset;
  }

  .euiRadio__input:checked + .euiRadio__circle {
    border: 0px solid #fff;
  }
`;

const LabelWrapper = styled.div<{ disabled?: boolean }>`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.disabled : theme.colors.text)};
  user-select: none;
`;

const DescriptionWrapper = styled.div`
  line-height: normal;
  color: ${({ theme }) => theme.colors.text};
`;

const TectonRadio: FC<TectonRadioProps> = ({ checked, disabled, label, id, onClick, description }) => {
  return (
    <StyledRadio
      data-testid={'acl-radio-options'}
      disabled={disabled}
      label={
        <>
          <FlexGroup direction="column" gutterSize="s">
            <LabelWrapper disabled={disabled}>
              <Text fontWeight="bold">{label}</Text>
            </LabelWrapper>
            {description && <DescriptionWrapper style={{ lineHeight: 'normal' }}>{description}</DescriptionWrapper>}
          </FlexGroup>
        </>
      }
      onClick={onClick}
      onChange={() => {}}
      id={id}
      checked={checked}
    />
  );
};

export default TectonRadio;
