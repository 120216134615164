import styled from '@emotion/styled';
import { FlexGroup } from '../_eui_components';

// We need this component to ensure we have full control on gap size
// from our theme. Which is defined in ThemeTecton.ts
// tecton/web/web-ui/src/components/@tecton/ComponentRedesign/Theme/ThemeTecton.ts
export const FlexGroupWrapper = styled(FlexGroup)<{
  direction?: 'row' | 'rowReverse' | 'column' | 'columnReverse' | undefined;
  gap?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}>`
  flex-direction: ${({ direction = 'column' }) => direction};
  gap: ${({ gap = 'l', theme }) => theme.gap[gap]};
  width: 100%;
`;

// Used in TitledItem and TitledItemEditable
export const TitledItemHorizontal = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  gap: 8px;
`;

export const TitleHorizontal = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  align-items: flex-start;
`;

export const TruncateHorizontal = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HorizontalContent = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
  padding: 0px ${({ theme }) => theme.padding.s};
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const TitledItemVertical = styled.div`
  display: inline-block;
`;

export const TitleVertical = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
`;

export const ContentVertical = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
`;

export const EditableFieldWrapper = styled.div<{ paddingTop?: string }>`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius.small};
  background-color: ${({ theme }) => theme.colors.body};
  padding: ${({ paddingTop = '6px' }) => paddingTop} 6px;

  &:hover {
    .euiPopover__anchor {
      text-decoration-line: underline;
    }
    text-decoration-line: underline;

    .account-type-diagonal-icon {
      text-decoration-line: underline;
      visibility: visible;
    }
  }

  .account-type-diagonal-icon {
    visibility: hidden;
  }
`;

export const LinkTextWrapper = styled.div`
  a:hover {
    text-decoration: none;
    color: unset;
  }
`;

export const CardContentTopPadding = styled.div`
  margin-top: ${({ theme }) => theme.padding.xl};
`;
