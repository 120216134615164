import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Global } from '@emotion/react';
import { Link } from '@tecton/ComponentRedesign';
const bannerHeight = 41;

const StyledBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #edf8ff;
  color: #022738;
  padding: 10px;
  text-align: center;
  font-weight: 400;
  line-height: 16.34px;
  height: ${bannerHeight}px;
`;

const BottomBanner = () => {
  return (
    <>
      <Global
        styles={{
          body: {
            paddingBottom: `${bannerHeight}px`,
          },
          '.sideNavigation .sideNav__footerContainer': {
            bottom: `${bannerHeight}px`,
          },
        }}
      />
      <StyledBanner>
        Check out our{' '}
        <Link href="https://docs.tecton.ai/docs/beta/tutorials/tecton-quick-start" target="_blank">
          Quickstart Tutorial
        </Link>{' '}
        to see how you can productionize an AI application with Tecton.
      </StyledBanner>
    </>
  );
};

export default BottomBanner;
