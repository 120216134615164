import {
  FCOFields,
  FCOType,
  FeatureForFCO,
  FeatureViewFCO,
  FeatureViewFCOFields,
  FeatureViewFCOType,
  FeatureViewInputDataSource,
  OfflineStoreType,
  OnlineStoreType,
  TemporalAggregateFeatureForFCO,
} from '../../core/types/fcoTypes';
import { Fco } from '../../types/tecton_proto/data/fco';
import {
  Aggregate,
  FeatureView,
  NewMaterializationParams,
  OnlineServingIndex,
} from '../../types/tecton_proto/data/feature_view';
import Pipeline, { InputNode, PipelineNode, PipelineNodeTypes } from '../../service/Pipeline';
import _, { isNull } from 'lodash';
import { durationToSeconds, secondsToHumanFriendlyString } from '../../utils/misc-utils';
import { Column, Schema as SCHEMA_PROPS } from '../../types/tecton_proto/common/schema';
import {
  dataTypeToString,
  ClusterConfigInterface,
  ClusterConfigType,
} from '../../utils/feature-view/feature-view-util';
import IdUtils from '../../utils/id-utils';
import { getAggregateFeatureWindow, getSharedFCOProperties } from './fcoUtils';
import { Id } from '../../types/tecton_proto/common/id';
import { AggregationFunction } from '../../types/tecton_proto/common/aggregation_function';

import { DataSourceType } from '../../types/tecton_proto/common/data_source_type';
import { ClusterConfig } from '../../types/tecton_proto/args/feature_view';

export const transformFeatureViewProtoToFeatureViewFCO: (fco: Fco) => FeatureViewFCO = (fco) => {
  const protoFeatureView: FeatureView = fco.feature_view!;
  const metadata = protoFeatureView.fco_metadata!;

  const featureViewPipeline = pipeline(protoFeatureView);
  const pipelineTransformationDataSourceInputs = getPipelineTransformationDataSourceInputs(protoFeatureView);
  const featureViewFeatures = getFeatures(protoFeatureView);
  const featureViewOnlineServingIndex = getOnlineServingIndex(protoFeatureView);
  const batchClusterConfiguration = protoFeatureView.materialization_params?.batch_materialization;
  const streamClusterConfiguration = protoFeatureView.materialization_params?.stream_materialization;

  const asFeatureViewFCO: FeatureViewFCO = {
    ...getSharedFCOProperties(metadata, protoFeatureView.feature_view_id),
    fcoType: FCOType.FEATURE_VIEW,
    archived: metadata.is_archived ?? false,
    entityNames: [],
    entityIds: getEntityIds(protoFeatureView),
    joinKeys: protoFeatureView.join_keys ?? [],
    timeKey: protoFeatureView.timestamp_key,
    featureViewType: FeatureViewFCOType.UNKNOWN,
    isTemporal: !!protoFeatureView.temporal,
    frameworkVersion: protoFeatureView.framework_version,
    fwVersion: protoFeatureView.fw_version,
    hasIncrementalBackfill: protoFeatureView.temporal && protoFeatureView.temporal.incremental_backfills ? true : false,
    isStream: getIsStream(protoFeatureView),
    isPush: getIsPush(protoFeatureView),
    isContinuous: getIsContinuous(protoFeatureView),
    isTemporalAggregate: !!protoFeatureView.temporal_aggregate,
    isRealtime: !!protoFeatureView.realtime_feature_view,
    isFeatureTable: !!protoFeatureView.feature_table,
    materializationParams: getMaterializationParams(protoFeatureView),
    canShowSparkJson: getCanShowSparkJson(batchClusterConfiguration),
    streamClusterConfiguration: getStreamClusterConfiguration(protoFeatureView),
    batchClusterConfiguration: getBatchClusterConfiguration(protoFeatureView),
    canShowStreamSparkJson: getCanShowStreamSparkJson(streamClusterConfiguration),
    materializationIntervalInSeconds: getMaterializationIntervalInSeconds(protoFeatureView),
    onlineMaterializationIntervalInSeconds: getOnlineMaterializationInSeconds(protoFeatureView),
    servingTtl: getServingTTl(protoFeatureView),
    materializationMaxSourceDataDelaySeconds: getMaterializationMaxSourceDelayInSeconds(protoFeatureView),
    materializationStartTimestamp: getMaterializationStartTimestamp(protoFeatureView),
    allTransformations: getAllTransformations(featureViewPipeline),
    allEntities: getAllEntities(protoFeatureView),
    features: featureViewFeatures,
    maxAggregationIntervalInSeconds: getMaxAggregationIntervalInSeconds(protoFeatureView),
    featureMap: getFeatureMap(featureViewFeatures),
    isMaterializationEnabled: getIsMaterializationEnabled(protoFeatureView),
    expectedFreshness: getExpectedFreshness(protoFeatureView),
    isMonitoringEnabled: getIsMonitoringEnabled(protoFeatureView),
    alertEmail: protoFeatureView.monitoring_params?.alert_email || undefined,
    temporalAggregateFeatures: getTemporalAggregateFeatures(protoFeatureView),
    temporalAggregateSlideInterval: getTemporalAggregateSlideInterval(protoFeatureView),
    temporalAggregateSecondaryKey: getTemporalAggregateSecondaryKey(protoFeatureView),
    isOnlineMaterializationEnabled: getIsOnlineMaterializationEnabled(protoFeatureView),
    isOfflineMaterializationEnabled: getIsOfflineMaterializationEnabled(protoFeatureView),
    dataSourceIds: getDataSourceIds(featureViewPipeline),
    featureServiceNamespace: undefined,
    viewSql: getFeatureViewViewSql(protoFeatureView),
    hasDependentFvs: getHasDependentFeatureViews(protoFeatureView, featureViewPipeline),
    dependentFeatureViews: getDependentFeatureViews(featureViewPipeline),
    batchTrigger: getBatchTrigger(protoFeatureView),
    featureServices: getFeatureServices(protoFeatureView, featureViewPipeline),
    onlineServingIndex: featureViewOnlineServingIndex,
    pipeline: featureViewPipeline, // This is needed for DataFlow.js file line 119  which looking for fvToRender.pipeline.allInternalNodes
    pipelineTransformationDataSourceInputs,
    isStreamIngest: getIsStreamIngest(protoFeatureView),
    isCachingEnabled: getIsCachingEnabled(protoFeatureView),
    cacheMaxAgeSeconds: getCacheMaxAgeSeconds(protoFeatureView),
    onDemandRequestDataSourceKeys: getOnDemandRequestDataSourceKeys(protoFeatureView, featureViewPipeline),
    [FeatureViewFCOFields.REALTIME_ENVIRONMENTS]: getRealtimeEnvironments(protoFeatureView),
    [FeatureViewFCOFields.IS_DATA_QUALITY_ENABLED]: getIsDataQualityEnabled(protoFeatureView),
    [FeatureViewFCOFields.SKIP_DEFAULT_EXPECTATIONS]: getSkipDefaultExpectations(protoFeatureView),
    [FeatureViewFCOFields.MANUAL_TRIGGER_BACKFILL_END_TIMESTAMP]: getManualTriggerBackfillTimestamp(protoFeatureView),
    [FeatureViewFCOFields.MAX_BACKFILL_INTERVAL]: getMaxBackfillInterval(protoFeatureView),
    [FeatureViewFCOFields.MATERIALIZATION_RUNTIME]: getMaterializationRuntime(protoFeatureView),
    [FeatureViewFCOFields.BATCH_COMPACTION_ENABLED]: getBatchCompactionEnabled(protoFeatureView),
    [FeatureViewFCOFields.STREAM_TILING_ENABLED]: getStreamTilingEnabledEnabled(protoFeatureView),
    [FeatureViewFCOFields.ONLINE_STORE_TYPE]: getOnlineStoreType(protoFeatureView),
    [FeatureViewFCOFields.OFFLINE_STORE_TYPE]: getOfflineStoreType(protoFeatureView),
    [FeatureViewFCOFields.PRIMARY_ENDPOINT]: getPrimaryEndpoint(protoFeatureView),
    [FeatureViewFCOFields.PUBLISH_FULL_FEATURES]: getPublishFullFeatures(protoFeatureView),
    [FeatureViewFCOFields.PUBLISH_START_TIME]: getPublishStartTime(protoFeatureView),
    [FCOFields.PREVENT_DESTROY]: protoFeatureView.validation_args?.args?.prevent_destroy,
    [FeatureViewFCOFields.BATCH_COMPUTE_MODE]: protoFeatureView?.batch_compute_mode,
  };

  // get Type AFTER INSTANTIATION
  // get feature map AFTER getting feature View
  // get entity names AFTER getting Entities

  // Feature View Type is calculated after instantiation because the logic from the initial wrapper class was dependent on a number of properties.
  // Ideally, this should just be a property passed from the API.
  // See notes below.
  asFeatureViewFCO.featureViewType = getFeatureViewType(asFeatureViewFCO);

  return asFeatureViewFCO;
};

const getPrimaryEndpoint = (featureView: FeatureView) => {
  if (featureView.materialization_params?.online_store_params?.redis) {
    return featureView.materialization_params?.online_store_params?.redis.primary_endpoint;
  }
  return undefined;
};

const getPublishFullFeatures = (featureView: FeatureView) => {
  if (featureView.materialization_params?.feature_publish_offline_store_config) {
    return featureView.materialization_params?.feature_publish_offline_store_config.publish_full_features;
  }
  return undefined;
};

const getPublishStartTime = (featureView: FeatureView) => {
  if (featureView.materialization_params?.feature_publish_offline_store_config?.publish_start_time) {
    return new Date(featureView.materialization_params?.feature_publish_offline_store_config.publish_start_time);
  }
  return undefined;
};

const getOnlineStoreType = (featureView: FeatureView) => {
  if (featureView.materialization_params?.online_store_params?.bigtable) {
    return OnlineStoreType.BIG_TABLE;
  }

  if (featureView.materialization_params?.online_store_params?.dynamo) {
    return OnlineStoreType.DYNAMO;
  }

  if (featureView.materialization_params?.online_store_params?.redis) {
    return OnlineStoreType.REDIS;
  }

  return undefined;
};

const getOfflineStoreType = (featureView: FeatureView) => {
  if (featureView.materialization_params?.offline_store_config?.delta) {
    return OfflineStoreType.DELTA;
  }

  if (featureView.materialization_params?.offline_store_config?.parquet) {
    return OfflineStoreType.PARQUET;
  }

  if (featureView.materialization_params?.offline_store_config?.subdirectory_override) {
    return OfflineStoreType.SUBDIRECTORY;
  }

  return undefined;
};

const getMaterializationRuntime = (featureView: FeatureView) => {
  return featureView.materialization_params?.tecton_materialization_runtime;
};

const getBatchCompactionEnabled = (featureView: FeatureView) => {
  return featureView.materialization_params?.compaction_enabled ? true : false;
};

const getStreamTilingEnabledEnabled = (featureView: FeatureView) => {
  return featureView.materialization_params?.stream_tiling_enabled ? true : false;
};

const getMaxBackfillInterval = (featureView: FeatureView) => {
  if (featureView.materialization_params?.max_backfill_interval) {
    return featureView.materialization_params?.max_backfill_interval.toString().replace('s', '');
  }
  return undefined;
};

const getManualTriggerBackfillTimestamp = (featureView: FeatureView) => {
  return featureView.materialization_params?.manual_trigger_backfill_end_timestamp;
};

const getSkipDefaultExpectations = (featureView: FeatureView) => {
  return featureView.data_quality_config?.skip_default_expectations;
};

const getIsDataQualityEnabled = (featureView: FeatureView) => {
  return featureView.data_quality_config?.data_quality_enabled;
};

const getRealtimeEnvironments = (featureView: FeatureView) => {
  if (!featureView.realtime_feature_view) {
    return [];
  }

  return featureView.realtime_feature_view.supported_environments?.map((env) => env.name ?? '') ?? [];
};

const getOnDemandRequestDataSourceKeys = (featureView: FeatureView, pipeline: Pipeline | undefined) => {
  if (!featureView.realtime_feature_view) {
    return [];
  }

  return [
    ...new Set(
      pipeline?.allLeafNodes
        .filter(
          (node: any) =>
            node.nodeType === PipelineNodeTypes.REQUEST_DATA_SOURCE && node.node.request_context.schema.fields
        )
        .map((i: any) => i.node.request_context.schema.fields.map((field: any) => field.name))
        .flat()
    ),
  ];
};

const getIsCachingEnabled = (protoFeatureView: FeatureView) => {
  return protoFeatureView.cache_config != undefined;
};

const getCacheMaxAgeSeconds = (protoFeatureView: FeatureView) => {
  return { seconds: protoFeatureView.cache_config?.max_age_seconds?.toString().replace('s', '') };
};

const getIsStreamIngest = (protoFeatureView: FeatureView) => {
  if (!getIsStream(protoFeatureView)) {
    return false;
  }

  if (!protoFeatureView.temporal_aggregate?.data_source_type && !protoFeatureView.temporal?.data_source_type) {
    return false;
  }

  const dataSourceType = protoFeatureView.temporal_aggregate
    ? protoFeatureView.temporal_aggregate.data_source_type
    : protoFeatureView.temporal!.data_source_type;

  return dataSourceType === DataSourceType.PUSH_NO_BATCH || dataSourceType === DataSourceType.PUSH_WITH_BATCH;
};

export const getFeatureServices: (featureView: FeatureView, fvPipeline: Pipeline | undefined) => string[] = (
  featureView,
  fvPipeline
) => {
  fvPipeline?.allInternalNodes.map((_: PipelineNode | undefined) => {
    return;
  });

  return [];
};

const pipeline: (featureView: FeatureView) => Pipeline | undefined = (featureView) => {
  if (!featureView.pipeline) {
    return undefined;
  } else {
    return new Pipeline(featureView.pipeline);
  }
};

const getPipelineTransformationDataSourceInputs = (featureView: FeatureView): FeatureViewInputDataSource[] => {
  if (!featureView.pipeline) {
    return [];
  }

  if (featureView?.pipeline?.root?.transformation_node?.inputs) {
    const dataSourceNodes: FeatureViewInputDataSource[] = [];
    // We only want the dataSource inputs
    featureView?.pipeline?.root?.transformation_node?.inputs
      .filter((input) => {
        return input?.node?.data_source_node;
      })
      .forEach((inputDataSource) => {
        if (inputDataSource.node?.data_source_node) {
          const value = inputDataSource.node?.data_source_node;
          const scheduleOffSet = value?.start_time_offset as string;

          let startTimeOffsetDisplay;

          if (scheduleOffSet) {
            startTimeOffsetDisplay = `FilteredSource(start_time_offset=${
              scheduleOffSet === '0s' ? '' : '-'
            }${secondsToHumanFriendlyString(durationToSeconds(scheduleOffSet?.replace('-', '')))})`;
          }
          const id = IdUtils.toStringId(inputDataSource.node?.data_source_node.virtual_data_source_id);
          // Add a new attribute for the display version of start_time_offset
          const featureViewInputDataSource: FeatureViewInputDataSource = {
            ...inputDataSource.node?.data_source_node,
            ...(scheduleOffSet ? { startTimeOffsetDisplay: startTimeOffsetDisplay } : {}),
            id, // Let's make this ID available so we can use it later.
          };

          dataSourceNodes.push(featureViewInputDataSource);
        }
      });

    return dataSourceNodes;
  }

  return [];
};

const getEntityIds: (featureView: FeatureView) => string[] = (featureView) => {
  if (!featureView.entity_ids) {
    return [];
  }
  return featureView.entity_ids?.map((id: Id) => IdUtils.toStringId(id));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getFeatureViewViewSql: (featureView: FeatureView) => string = (featureView) => {
  return '';
};

/* BUILT AND READY FOR TESTING */
const getHasDependentFeatureViews: (featureView: FeatureView, pipeline: Pipeline | undefined) => boolean = (
  featureView,
  pipeline
) => {
  if (!pipeline) {
    return false;
  }
  const allInternalFeatureViewNodes = pipeline.allInternalNodes.filter((transformNode: any) => {
    return !_.isEmpty(
      transformNode.inputs.filter((input: InputNode) => {
        return input.node.nodeType === 'feature_view_node';
      })
    );
  });

  return !!featureView.realtime_feature_view && !_.isEmpty(allInternalFeatureViewNodes);
};

const getBatchTrigger: (featureView: FeatureView) => string | undefined = (featureView) => {
  return featureView.batch_trigger
    ? featureView.batch_trigger.toLowerCase().replace('batch_trigger_type_', '')
    : undefined;
};

const getDependentFeatureViews: (featureViewPipeline: Pipeline | undefined) => InputNode[] = (featureViewPipeline) => {
  const dependentFeatureViews: InputNode[] = [];
  if (featureViewPipeline) {
    featureViewPipeline.allInternalNodes.filter((transformNode: PipelineNode | undefined) => {
      transformNode &&
        transformNode.inputs.filter((input: InputNode) => {
          if (input.node.type === 'feature_view') {
            dependentFeatureViews.push(input);
          }
        });
    });
  }

  return dependentFeatureViews;
};

const getDataSourceIds: (featureViewPipeline: Pipeline | undefined) => string[] = (featureViewPipeline) => {
  if (featureViewPipeline) {
    return featureViewPipeline.allLeafNodes
      .map((node: PipelineNode | undefined) => {
        return node && node.nodeType === PipelineNodeTypes.DATA_SOURCE ? node.innerNodeId : '';
      })
      .filter((id: string | null | undefined) => {
        if (id && !isNull(id)) {
          return true;
        }
        return false;
      }) as string[];
  }

  return [];
};

const _aggregation_function_name_from_proto = (protoName: string | undefined) => {
  return protoName ? protoName.replace('AGGREGATION_FUNCTION_', '').toLowerCase() : '';
};

const getTemporalAggregateFeatures: (featureView: FeatureView) => TemporalAggregateFeatureForFCO[] = (featureView) => {
  const features: TemporalAggregateFeatureForFCO[] = [];

  const proto = featureView.temporal_aggregate;
  if (!proto) {
    return features;
  }

  _.map(proto.features, (aggregationFeature) => {
    const featureViewWindow = getAggregateFeatureWindow(aggregationFeature);
    features.push({
      name: aggregationFeature.output_feature_name ?? '',
      aggregation:
        _aggregation_function_name_from_proto(aggregationFeature.function) +
        '(' +
        aggregationFeature.input_feature_name +
        ')',
      aggregationWindow: secondsToHumanFriendlyString(featureViewWindow),
    });
  });

  _.map(proto.secondary_key_output_columns, (secondaryKeyOutputColumn) => {
    const durationInSeconds =
      durationToSeconds(secondaryKeyOutputColumn.time_window?.relative_time_window?.window_end ?? '0s') -
      durationToSeconds(secondaryKeyOutputColumn.time_window?.relative_time_window?.window_start ?? '0s');

    features.push({
      name: secondaryKeyOutputColumn.name ?? '',
      aggregation: 'collect_list(' + proto.aggregation_secondary_key + ')',
      aggregationWindow: secondsToHumanFriendlyString(durationInSeconds),
    });
  });

  return features;
};

const getTemporalAggregateSlideInterval = (featureView: FeatureView): string | undefined => {
  return featureView?.temporal_aggregate?.slide_interval_string;
};

const getTemporalAggregateSecondaryKey = (featureView: FeatureView): string | undefined => {
  return featureView?.temporal_aggregate?.aggregation_secondary_key;
};

const getIsMonitoringEnabled: (featureView: FeatureView) => boolean = (featureView) => {
  return featureView.materialization_enabled &&
    featureView.monitoring_params &&
    featureView.monitoring_params.monitor_freshness
    ? true
    : false;
};

const getExpectedFreshness: (featureView: FeatureView) => number | undefined = (featureView) => {
  return featureView.monitoring_params?.expected_feature_freshness
    ? secondsToHumanFriendlyString(durationToSeconds(featureView.monitoring_params?.expected_feature_freshness))
    : undefined;
};

const getIsMaterializationEnabled: (feature: FeatureView) => boolean = (featureView) => {
  return featureView.materialization_enabled ?? false;
};

const getFeatures: (feature: FeatureView) => FeatureForFCO[] = (featureView) => {
  const features: FeatureForFCO[] = [];

  const joinKeys = featureView.join_keys || [];
  const schemas = featureView.schemas;
  if (!schemas) {
    return features;
  }
  const materialization_schema: SCHEMA_PROPS | undefined = schemas.materialization_schema;
  if (!materialization_schema) {
    return features;
  }

  if (featureView.temporal_aggregate && featureView.temporal_aggregate.features) {
    featureView.temporal_aggregate.features.forEach((feature: Aggregate) => {
      const aggregateAttributes: { key?: string; value?: string }[] = [];

      Object.keys(feature).forEach((key, index) => {
        const value = Object.values(feature);
        aggregateAttributes.push({ key, value: value[index] });
      });

      features.push({
        name: feature.output_feature_name ?? '',
        type: 'aggregation',
        aggregateAttributes,
      });
    });
  } else {
    const columns = materialization_schema.columns;
    (columns || []).forEach((column: Column) => {
      if (column.name && joinKeys.includes(column.name)) {
        return;
      }
      if (column.name === featureView.timestamp_key) {
        return;
      }
      features.push({
        name: column.name ?? '',
        type: dataTypeToString(column.offline_data_type),
      });
    });
  }
  return features;
};

const getOnlineServingIndex = (feature: FeatureView): OnlineServingIndex | undefined => {
  return feature.online_serving_index;
};

const getMaxAggregationIntervalInSeconds: (feature: FeatureView) => number | undefined = (featureView) => {
  if (featureView.temporal_aggregate && featureView.temporal_aggregate.features) {
    const featureDurations = featureView.temporal_aggregate.features.map((aggregationFeature) => {
      return getAggregateFeatureWindow(aggregationFeature);
    });
    return _.max(featureDurations);
  }
  return undefined;
};

const getAllEntities: (featureView: FeatureView) => string[] = (featureView) => {
  return featureView.entity_ids?.map((id) => IdUtils.toStringId(id)) ?? [];
};

const getAllTransformations: (pipeline: Pipeline | undefined) => string[] = (pipeline) => {
  const transformationIds: string[] = [];

  pipeline?.allInternalNodes.map((node: PipelineNode | undefined) => {
    if (node?.transformationNode && node?.transformationNode.transformationId) {
      transformationIds.push(node?.transformationNode.transformationId);
    }
  });

  return transformationIds;
};

const getMaterializationMaxSourceDelayInSeconds: (featureView: FeatureView) => number = (featureView) => {
  const maxDelay = featureView.materialization_params?.max_source_data_delay || '0';
  return durationToSeconds(maxDelay);
};

const getServingTTl: (featureView: FeatureView) => number | undefined = (featureView) => {
  if (featureView?.temporal?.serving_ttl) {
    return secondsToHumanFriendlyString(durationToSeconds(featureView.temporal.serving_ttl));
  }

  if (featureView?.feature_table?.serving_ttl) {
    return secondsToHumanFriendlyString(durationToSeconds(featureView.feature_table.serving_ttl));
  }

  return undefined;
};

const getIsStream: (featureView: FeatureView) => boolean = (featureView) => {
  const stream_data_source_types = ['PUSH_WITH_BATCH', 'PUSH_NO_BATCH', 'STREAM_WITH_BATCH'];
  if (featureView.temporal && featureView.temporal.data_source_type) {
    return stream_data_source_types.includes(featureView.temporal.data_source_type);
  }

  if (featureView.temporal_aggregate && featureView.temporal_aggregate.data_source_type) {
    return stream_data_source_types.includes(featureView.temporal_aggregate.data_source_type);
  }
  return false;
};

const getIsPush: (featureView: FeatureView) => boolean = (featureView) => {
  const isPush =
    featureView.temporal?.data_source_type === 'PUSH_NO_BATCH' ||
    featureView.temporal?.data_source_type === 'PUSH_WITH_BATCH' ||
    featureView.temporal_aggregate?.data_source_type === 'PUSH_NO_BATCH' ||
    featureView.temporal_aggregate?.data_source_type === 'PUSH_WITH_BATCH';
  return isPush;
};

const getIsContinuous: (featureView: FeatureView) => boolean = (featureView) => {
  let aggregateCondition = false;
  let temporalCondition = false;

  if (featureView.temporal_aggregate && featureView.temporal_aggregate.is_continuous) {
    aggregateCondition = featureView.temporal_aggregate.is_continuous;
  }

  if (featureView.temporal && featureView.temporal.is_continuous) {
    temporalCondition = featureView.temporal.is_continuous;
  }

  return aggregateCondition || temporalCondition;
};

const getCanShowSparkJson: (configuration: ClusterConfig | undefined) => boolean = (configuration) => {
  if (!configuration) {
    return false;
  }

  if ('json_emr' in configuration || 'json-databricks' in configuration) {
    return true;
  }

  return false;
};

const getCanShowStreamSparkJson: (config: ClusterConfig | undefined) => boolean = (config) => {
  if (!config) {
    return false;
  }

  if ('json_emr' in config || 'json_databricks' in config) {
    return true;
  }

  return false;
};

const getMaterializationIntervalInSeconds: (featureView: FeatureView) => number = (featureView) => {
  if (featureView.temporal_aggregate) {
    return durationToSeconds(featureView.temporal_aggregate.slide_interval);
  }

  if (
    featureView.temporal &&
    featureView.materialization_params &&
    featureView.materialization_params.schedule_interval
  ) {
    durationToSeconds(featureView.materialization_params.schedule_interval);
  }
  return 0;
};
const getMaterializationParams: (featureView: FeatureView) => NewMaterializationParams = (featureView) => {
  return featureView.materialization_params ?? {};
};

const getMaterializationStartTimestamp: (featureView: FeatureView) => string | undefined = (featureView) => {
  return featureView.materialization_params?.materialization_start_timestamp ?? undefined;
};

const getIsOnlineMaterializationEnabled: (featureView: FeatureView) => boolean | undefined = (featureView) => {
  // TODO: currently follow the old code
  return featureView.materialization_enabled && featureView.materialization_params?.writes_to_online_store;
};

const getIsOfflineMaterializationEnabled: (featureView: FeatureView) => boolean | undefined = (featureView) => {
  return featureView.materialization_enabled && featureView.materialization_params?.writes_to_offline_store;
};

const getFeatureViewType: (featureView: FeatureViewFCO) => FeatureViewFCOType = (featureView) => {
  if (
    featureView.isTemporal &&
    featureView.hasIncrementalBackfill &&
    featureView.frameworkVersion &&
    featureView.frameworkVersion >= 4
  ) {
    return FeatureViewFCOType.BATCH;
  }

  if (featureView.isTemporal) {
    return featureView.isStream ? FeatureViewFCOType.STREAM : FeatureViewFCOType.BATCH;
  }

  if (featureView.isTemporalAggregate) {
    if (featureView.frameworkVersion && featureView.frameworkVersion >= 4) {
      return featureView.isStream ? FeatureViewFCOType.STREAM : FeatureViewFCOType.BATCH;
    } else {
      return featureView.isStream
        ? FeatureViewFCOType.STREAM_WINDOW_AGGREGATE
        : FeatureViewFCOType.BATCH_WINDOW_AGGREGATE;
    }
  }

  if (featureView.isRealtime) {
    return FeatureViewFCOType.REALTIME;
  }

  if (featureView.isFeatureTable) {
    return FeatureViewFCOType.FEATURE_TABLE;
  }

  return FeatureViewFCOType.UNKNOWN;
};

const getFeatureMap: (features: FeatureForFCO[]) => _.Dictionary<FeatureForFCO> = (features) => {
  return _.keyBy(features, 'name');
};

const getMaterializationConfiguration: (
  clusterConfig: ClusterConfig | undefined
) => ClusterConfigInterface | undefined = (clusterConfig) => {
  if (clusterConfig?.existing_cluster) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.existing_cluster,
      type: ClusterConfigType.EXISTING_CLUSTER,
    };
    return config;
  }

  if (clusterConfig?.new_databricks) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.new_databricks,
      type: ClusterConfigType.NEW_DATABRICKS,
    };
    return config;
  }

  if (clusterConfig?.new_emr) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.new_emr,
      type: ClusterConfigType.NEW_EMR,
    };
    return config;
  }

  if (clusterConfig?.implicit_config) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.implicit_config,
      type: ClusterConfigType.IMPLICIT_CONFIG,
    };
    return config;
  }

  if (clusterConfig?.json_databricks) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.json_databricks,
      type: ClusterConfigType.JSON_DATABRICKS,
    };
    return config;
  }

  if (clusterConfig?.json_emr) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.json_emr,
      type: ClusterConfigType.JSON_EMR,
    };
    return config;
  }

  if (clusterConfig?.json_dataproc) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.json_dataproc,
      type: ClusterConfigType.JSON_DATAPROC,
    };
    return config;
  }

  if (clusterConfig?.rift) {
    const config: ClusterConfigInterface = {
      clusterConfig: clusterConfig?.rift,
      type: ClusterConfigType.RIFT,
    };
    return config;
  }

  return undefined;
  // we should never get down here
};

const getBatchClusterConfiguration: (featureView: FeatureView) => ClusterConfigInterface | undefined = (
  featureView
) => {
  const clusterConfig: ClusterConfig | undefined = featureView?.materialization_params?.batch_materialization;
  return getMaterializationConfiguration(clusterConfig);
};

const getStreamClusterConfiguration = (featureView: FeatureView): ClusterConfigInterface | undefined => {
  const clusterConfig: ClusterConfig | undefined = featureView?.materialization_params?.stream_materialization;
  return getMaterializationConfiguration(clusterConfig);
};

const getOnlineMaterializationInSeconds: (featureView: FeatureView) => number | undefined = (featureView) => {
  if (featureView.temporal_aggregate && featureView.temporal_aggregate.slide_interval) {
    return durationToSeconds(featureView.temporal_aggregate.slide_interval);
  }

  if (featureView.temporal) {
    return getMaterializationIntervalInSeconds(featureView);
  }

  return undefined;
};

export const getAggregationFunctionDisplayName = (aggregationFunction: AggregationFunction): string => {
  let output = '-';

  switch (aggregationFunction) {
    case AggregationFunction.AGGREGATION_FUNCTION_COUNT:
      output = 'count';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_SUM:
      output = 'sum';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_MEAN:
      output = 'mean';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_LAST:
      output = 'last';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_MIN:
      output = 'min';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_MAX:
      output = 'max';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_LAST_DISTINCT_N:
      output = 'last distinct n';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_VAR_SAMP:
      output = 'var samp';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_VAR_POP:
      output = 'var pop';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_STDDEV_SAMP:
      output = 'stddev samp';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_STDDEV_POP:
      output = 'stddev pop';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_LAST_NON_DISTINCT_N:
      output = 'last non distinct n';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_FIRST_NON_DISTINCT_N:
      output = 'first non distinct n';
      break;

    case AggregationFunction.AGGREGATION_FUNCTION_FIRST_DISTINCT_N:
      output = 'first distinct n';
      break;
    case AggregationFunction.AGGREGATION_FUNCTION_APPROX_COUNT_DISTINCT:
      output = 'approx count distinct';
      break;
    default:
      output = 'unknown';
  }

  return output;
};

export const REQUEST_DATA_SOURCE = 'Request Data Source';

export const getFeatureViewPythonCode = (workspaceName: string, featureViewName: string) => {
  return `
      import tecton

      ws = tecton.get_workspace('${workspaceName}')
      fv = ws.get_feature_view('${featureViewName}')
      df = fv.get_historical_features(from_source=True)`;
};
